ScrollReveal().reveal(".one", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".two", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".three", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".four", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".five", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".seven", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".eight", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".nine", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});
ScrollReveal().reveal(".ten", {
  useDelay: "always",
  origin: "bottom",
  distance: "40px",
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  reset: true,
  opacity: 0.2,
  duration: 600,
  scale: 0.9,
});

$(window).scroll(function () {
  console.log("scrolling");
  if ($(this).scrollTop() > 50) {
    $(".navbar-trans").addClass("afterscroll");
  } else {
    $(".navbar-trans").removeClass("afterscroll");
  }
});
